package component

import ChatMessage
import ChatSession
import Lang
import csstype.ClassName
import csstype.Height
import csstype.px
import db.FirebaseJs
import db.firebase
import emotion.react.css
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.Audio
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.get
import postEmailFromChat
import react.*
import react.dom.html.ButtonType
import react.dom.html.InputType
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.textarea
import kotlin.js.Date

val ChatComponent = FC<Props> { props ->
    var chatSession by useState(ChatSession("", mapOf()))
    var isOpened by useState(false)
    var preparedMessage by useState("")
    var textRef = useRef<HTMLTextAreaElement>()

    fun scrollToBottom() {
        val messageList = document.getElementsByClassName("message-list")[0]
        if (messageList != null) messageList.scrollTop = messageList.scrollHeight.toDouble()
    }

    useEffectOnce {
        console.log("useEffectOnce")
        GlobalScope.launch {
            console.log("GlobalScope")
            firebase.onMessages.collect { newData ->
                console.log("received new message:" + Json.encodeToString(newData))
                val newestMessage = newData.messages.values.sortedByDescending { it.timestamp.toDouble() }[0]
                if (newestMessage.visible) {
                    isOpened = true
                    if (newestMessage.sender.startsWith("user")) {
                        //outgoing
                        Audio("assets/notify.mp3").play()
                    } else {
                        //incoming
                        Audio("assets/notify.mp3").play()
                    }
                }
                chatSession = newData
            }
        }
    }

    div {
        className = ClassName("chat-action-button" + if (isOpened) " closed" else "")
        img {
            src = "assets/chat_icon.svg"
            alt = ""
            onClick = {
                isOpened = !isOpened
            }
        }
    }
    form {
        var fullClassName = "chat"
        if (!isOpened) fullClassName += " closed"
        className = ClassName(fullClassName)
        div {
            className = ClassName("chat-head")
            img {
                src = "assets/chat_icon.svg"
                alt = ""
            }
            div {
                className = ClassName("chat-head-text")
                h2 {
                    +Lang.getSelected().chat_title
                }
                div {
                    className = ClassName("chat-info")
                    +Lang.getSelected().chat_info
                }
                //TODO: CSS responsibility for chat-status
                /*div {
                    className = ClassName("chat-status")
                    +Lang.getSelected().chat_status
                }*/
            }
            div {
                className = ClassName("chat-button")
                onClick = {
                    isOpened = !isOpened
                }
            }
        }

        div {
            className = ClassName("message-list")
            div {
                className = ClassName("chat-name")
                +"SessionId: ${firebase.getSessionId()}"
            }
            chatSession.messages.values.sortedBy { it.timestamp.toDouble() }.forEach {
                if (it.visible)
                    MessageComponent {
                        message = it
                    }
            }
        }
        div {
            className = ClassName("chat-input")
            textarea {
                form = "chat"
                ref = textRef
                value = preparedMessage
                placeholder = Lang.getSelected().chat_input_placeholder
                onChange = { event ->
                    preparedMessage = event.target.value
                    textRef.current?.style?.height = "auto"
                    textRef.current?.style?.height = (((textRef.current?.scrollHeight ?: 0) - 10)).toString() + "px"
                }
                onKeyUp = { e ->
                    if (e.key == "Enter" && !e.ctrlKey) {
                        emailMessage(preparedMessage)
                        firebase.sendMessage(preparedMessage)
                        preparedMessage = ""
                        textRef.current?.style?.height = "auto"
                    }
                }
            }
            div {
                className = ClassName("chat-button")
                onClick = {
                    emailMessage(preparedMessage)
                    firebase.sendMessage(preparedMessage)
                    preparedMessage = ""
                }
            }
        }
    }
    window.requestAnimationFrame { _ -> scrollToBottom() }
}

external interface ChatMessageProps : Props {
    var message: ChatMessage
}

enum class FillStatus {
    IDLE, FILLING_EMAIL, FILLING_PHONE, FILLED
}

val MessageComponent = FC<ChatMessageProps> { props ->
    var fillStatus by useState(FillStatus.IDLE)
    var contactInfo by useState("")
    div {
        var fullClassName = "message"
        if (props.message.sender.startsWith("user")) {
            fullClassName += " my-message"
        } else {
            img {
                className = ClassName("message-icon")
                src = "assets/chat/icon.png"
            }
        }
        className = ClassName(fullClassName)

        div {
            className = ClassName("message-sender")
            +props.message.sender
        }
        div {
            className = ClassName("message-content")
            +props.message.message
        }
        div {
            className = ClassName("message-timestamp")
            val time = Date(props.message.timestamp.toDoubleOrNull() ?: Date.now())
            +time.getHours().toString()
            +":"
            +time.getMinutes().toString().padStart(2, '0')
            +" "
        }
    }

    if (props.message.isAskedForContact() && fillStatus == FillStatus.IDLE) {
        fun focusLast() {
            window.requestAnimationFrame { _ ->
                val inputs = document.getElementsByClassName("message-fill-info-input")
                (inputs[inputs.length - 1] as HTMLElement).focus()
            }
        }
        div {
            className = ClassName("message-ask-info")
            div {
                +Lang.getSelected().chat_message_ask_info_email
                onClick = { event ->
                    fillStatus = FillStatus.FILLING_EMAIL
                    focusLast()
                }
            }
            div {
                +Lang.getSelected().chat_message_ask_info_phone
                onClick = { event ->
                    fillStatus = FillStatus.FILLING_PHONE
                    focusLast()
                }
            }
        }
    }

    val infoType = if (fillStatus == FillStatus.FILLING_EMAIL) Lang.getSelected().chat_message_ask_info_email else Lang.getSelected().chat_message_ask_info_phone
    fun sendContactInfo() {
        fillStatus = FillStatus.FILLED
        props.message.respondedAskContact()
        firebase.updateMessage(props.message)
        emailMessage(props.message.message)
        firebase.sendMessage("$infoType $contactInfo")
    }

    if (fillStatus == FillStatus.FILLING_EMAIL || fillStatus == FillStatus.FILLING_PHONE) {
        form {
            className = ClassName("message-fill-info")
            input {
                type = if (fillStatus == FillStatus.FILLING_EMAIL) InputType.email else InputType.tel
                className = ClassName("message-fill-info-input")
                placeholder = if (fillStatus == FillStatus.FILLING_EMAIL) Lang.getSelected().chat_message_fill_info_input_email else Lang.getSelected().chat_message_fill_info_input_phone
                onChange = { event ->
                    contactInfo = event.target.value
                }
                onKeyUp = { e ->
                    if (e.key == "Enter") {
                        sendContactInfo()
                    }
                }
            }
            button {
                className = ClassName("chat-button")
                type = ButtonType.submit
                onClick = { sendContactInfo() }
                value = ""
            }
            onSubmit = { sendContactInfo() }
        }
    }
}

fun emailMessage(message: String) {
    GlobalScope.launch {
        console.log("sending message: $message")
        val userInfo = " userAgent: ${window.navigator.userAgent} " +
                " language: ${window.navigator.language}" +
                " location: ${window.location} "
        postEmailFromChat(message, firebase.getSessionId(), userInfo)
    }
}
