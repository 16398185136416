import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

@Serializable
data class ChatMessage(
    val timestamp: String,
    val message: String,
    val sender: String,
    val visible: Boolean = true,
    var special: String = ""
) {
    fun toJsonString(): String {
        return Json.encodeToString(serializer(), this)
    }

    fun askForContact() {
        this.special += "#ask-contact"
    }

    fun isAskedForContact(): Boolean {
        return special.contains("#ask-contact")
    }

    fun respondedAskContact() {
        special = special.replace("#ask-contact", "#responed-ask-contact")
    }
}

@Serializable
data class ChatSession(var sessionId: String,
                       @Serializable
                       val messages: Map<String,ChatMessage>) {
    fun toJsonString(): String {
        return Json.encodeToString(serializer(), this)
    }
}
