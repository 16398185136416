package view

import Lang
import component.ContactForm
import component.JumpButton
import component.SectionHeading
import csstype.ClassName
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import postEmail
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span
import react.key
import react.useState
import kotlinx.browser.window
import react.dom.html.ReactHTML.button

private val scope = MainScope()

val ContactSection = FC<Props> { props ->
    var contactFormSend by useState(false)
    div {
        id = "contact"
        className = ClassName("section")
        div {
            className = ClassName("split")
            div {
                SectionHeading {
                    title = Lang.getSelected().contact_title
                }
                span { +Lang.getSelected().contact_list_header }
                span {
                    li {
                        span { +Lang.getSelected().contact_list_item_1 }
                    }
                    li {
                        span { +Lang.getSelected().contact_list_item_2 }
                    }
                    li {
                        span { +Lang.getSelected().contact_list_item_3 }
                    }
                }
            }
            div {
                button {
                    className = ClassName("button orange")
                    onClick = {
                        window.open("https://2xb6kziq3h8.typeform.com/to/onht4KOO", "_blank")
                    }
                    +Lang.getSelected().contact_form_title
                }
            }
        }
    }

}
