package view

import Lang
import component.JumpButton
import component.SectionHeading
import csstype.ClassName
import db.firebase
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.p

@Serializable
data class FAQ(
        val question: String,
        val answer: String,
)

val FAQSection = FC<Props> { props ->
    div {
        id = "faq"
        div {
            className = ClassName("section split")
            div {
                SectionHeading {
                    title = Lang.getSelected().faq_title
                }
                span {
                    className = ClassName("listing")
                    Lang.getSelected().faqs.map {
                        div {
                            className = ClassName("question")
                            h3 {
                                +it.question
                            }
                            p {
                                +it.answer
                            }
                        }
                    }
                }
            }
            div {
                img {
                    src = "assets/question_mark_icon.svg"
                    alt = ""
                }
            }
        }
    }
}
