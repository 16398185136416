package component

import csstype.ClassName
import kotlinx.browser.document
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollToOptions
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.button

external interface JumpButtonProps : Props {
    var scrollTo: String
    var className: ClassName?
    var onClick: ()->Unit
    var children: ReactNode
}

val JumpButton = FC<JumpButtonProps> { props ->
    button{
        className = ClassName("button" +  if(props.className != undefined) " " + props.className.toString() else "")
        onClick = {
            document.getElementById(props.scrollTo.removePrefix("#"))?.scrollIntoView(ScrollToOptions(behavior = org.w3c.dom.ScrollBehavior.SMOOTH))
            console.log(document.getElementById(props.scrollTo.removePrefix("#")))
            props.onClick()
        }
        + props.children
    }
}
