package view

import Lang
import component.JumpButton
import component.reactDropdown.BootstrapDropdownButton
import component.reactDropdown.BootstrapDropdownItem
import csstype.ClassName
import react.FC
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.useState
import shared.SetLanguageProps

val Header = FC<SetLanguageProps> { props ->
    var openMenu by useState(false)

    div {
        id = "header"
        a {
            href = "https://cexbit.com/"
            img {
                id = "logo"
                alt = "Cexbit.com"
                src = "assets/cexbit_logo_min.svg"
            }
        }

        div {
            a {
                id = "menuContainer"
                href = " https://calendly.com/mirek-behan/15min"
                img {
                    alt = "Calendar"
                    src = "assets/calendar.svg"
                }
                span {
                    +Lang.getSelected().header_book
                }
            }
        }

        div {
            id = "menuContainer"
            img {
                id = "menuContainerMobile"
                alt = "menuIcon"
                src = "assets/menu_icon.svg"
                onClick = {
                    openMenu = !openMenu
                }
            }
            span {
                id = "menus"
                if (!openMenu) {
                    className = ClassName("menu-closed")
                }
                JumpButton {
                    scrollTo = "#main"
                    span { +Lang.getSelected().header_menu_home }
                    onClick = {
                        console.log("onclick")
                        openMenu = !openMenu
                    }
                }
                JumpButton {
                    className = ClassName("menu")
                    scrollTo = "#services"
                    span { +Lang.getSelected().header_menu_services }
                    onClick = {
                        openMenu = !openMenu
                    }
                }
//                JumpButton {
//                    className = ClassName("menu")
//                    scrollTo = "#products"
//                    span { +Lang.getSelected().header_menu_products }
//                    onClick = {
//                        openMenu = !openMenu
//                    }
//                }
                JumpButton {
                    className = ClassName("menu")
                    scrollTo = "#contact"
                    span { +Lang.getSelected().header_menu_contact }
                    onClick = {
                        openMenu = !openMenu
                    }
                }
                BootstrapDropdownButton {
                    id = "language-selection"
                    title = Lang.getSelected().header_menu_language

                    Lang.getAll().forEach { lang ->
                        BootstrapDropdownItem {
                            value = lang.shortcut
                            key = lang.key
                            onClick = {
                                props.setLang(value)
                                openMenu = !openMenu
                            }
                            +lang.label
                        }
                    }
                }
            }
        }
    }
}
