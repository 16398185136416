package view

import Lang
import component.LogoIcon
import component.SectionHeading
import csstype.ClassName
import db.firebase
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.key

val TechSection = FC<Props> { props ->
    div {
        id = "tech"
        className = ClassName("section")
        SectionHeading {
            title = Lang.getSelected().tech_stack
        }
        p {
            +Lang.getSelected().tech_stack_description
        }

        div {
            className = ClassName("icon-grid")
            LogoIcon {
                image = "assets/techstack/react_logo_icon.svg"
                link = "https://reactjs.org/"
                title = "React Native"
            }
            LogoIcon {
                image = "assets/techstack/kotlin_multiplatform_logo_icon.svg"
                link = "https://kotlinlang.org/"
                title = "Kotlin"
            }
            LogoIcon {
                image = "assets/techstack/git_logo_icon.svg"
                link = "https://git-scm.com/"
                title = "Git"
            }
            LogoIcon {
                image = "assets/techstack/figma_logo_icon.svg"
                link = "https://www.figma.com/"
                title = "Figma"
            }
            LogoIcon {
                image = "assets/techstack/g_cloud_logo_icon.svg"
                link = "https://cloud.google.com/"
                title = "Google Cloud"
            }
            LogoIcon {
                image = "assets/techstack/firebase_logo_icon.svg"
                link = "https://firebase.google.com/"
                title = "Firebase"
            }
            LogoIcon {
                image = "assets/techstack/javascript_logo_icon.svg"
                link = "https://www.javascript.com/"
                title = "JavaScript"
            }
            LogoIcon {
                image = "assets/techstack/typescript_logo_icon.svg"
                link = "https://www.typescriptlang.org/"
                title = "TypeScript"
            }
            LogoIcon {
                image = "assets/techstack/compose_logo_icon.svg"
                link = "https://www.compose.com/"
                title = "Compose"
            }
        }
    }
}
