package db

import ChatMessage
import ChatSession
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.firebase
import dev.gitlive.firebase.firestore.errorToException
import dev.gitlive.firebase.firestore.firestore
import dev.gitlive.firebase.initialize
import getRandomString
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import org.w3c.dom.set
import postEmail
import kotlin.js.Date

val firebaseOptions: FirebaseOptions = FirebaseOptions(
    applicationId = "1:79992221228:web:b4f1ac1713995d7055ab89",
    apiKey = "AIzaSyD_DDtBhLwC3L5XBHu0Nrc4k2fr3bHkYjc",
    databaseUrl = null,
    gaTrackingId = null,
    storageBucket = "cexbit-website.appspot.com",
    projectId = "cexbit-website",
    gcmSenderId = "79992221228",
    authDomain = "cexbit-website.firebaseapp.com",
)

val firebaseApp = Firebase.initialize(options = firebaseOptions)

val firebase = FirebaseJs()

class FirebaseJs {

    private val db: firebase.firestore.Firestore = Firebase.firestore(firebaseApp).js

    private val messages = mutableMapOf<String, ChatMessage>()

    private var sessionId: String? = null

    fun getSessionId(): String {
        if (sessionId == null) {
            sessionId = prepareSessionId()
        }
        return sessionId ?: "undefined"
    }

    @Serializable
    data class UserInfo(val userAgent: String, val language: String)

    private fun prepareSessionId(): String {
        val userInfo = UserInfo(
            window.navigator.userAgent,
            window.navigator.language
        )
        // new user
        if (localStorage["sessionId"].isNullOrBlank()){
            localStorage["sessionId"] = getRandomString(16)
            sendSystemMessage("Nový uživatel " + Json.encodeToString(userInfo))
            console.log("Nový uživatel " + Json.encodeToString(userInfo))
        } else {
            sendSystemMessage("Opakovaný uživatel " + Json.encodeToString(userInfo))
            console.log("Opakovaný uživatel " + Json.encodeToString(userInfo))
        }
        val sessionId = localStorage["sessionId"].toString()
        console.log("localStorage[\"sessionId\"]:$sessionId")
        return sessionId
    }

    private fun send(chatMessage: ChatMessage) {
        GlobalScope.launch {
            val newMessages = mutableMapOf<String, ChatMessage>()
            newMessages.putAll(messages)
            newMessages[chatMessage.timestamp] = chatMessage
            val chatSession = ChatSession(sessionId = getSessionId(), messages = newMessages)
            console.log("saving into db collection chatSession:" + chatSession.toJsonString())
            db.collection("chat").doc(getSessionId()).set(JSON.parse(chatSession.toJsonString()), JSON.parse("{\"merge\":\"true\"}"))
            console.log("sending support email")
            postEmail("", "", "", chatMessage.toJsonString())
        }
    }
    fun sendMessage(message: String) {
        if(message.isEmpty() || message.trim().isEmpty()) return
        val chatMessage = ChatMessage(Date.now().toString(),message, "user-" + getSessionId())
        console.log("sendMessage:" + chatMessage.toJsonString())
        send(chatMessage)
    }

    fun sendMessageWithContactRequest(message: String) {
        if(message.isEmpty()) return
        val chatMessage = ChatMessage(Date.now().toString(),message, "user-" + getSessionId(), visible = true)
        chatMessage.askForContact()
        console.log("sendMessage:" + chatMessage.toJsonString())
        send(chatMessage)
    }

    fun updateMessage(chatMessage: ChatMessage){
        console.log("update chat message:")
        console.log(chatMessage)
        send(chatMessage)
    }

    fun localMessage(message: String) {
        val chatMessage = ChatMessage(Date.now().toString(),message,"system")
        console.log("sendSystemMessage:" + chatMessage.toJsonString())
        send(chatMessage)
    }
    fun sendSystemMessage(message: String) {
        val chatMessage = ChatMessage(Date.now().toString(),message,"system", visible = false)
        console.log("sendSystemMessage:" + chatMessage.toJsonString())
        send(chatMessage)
    }

    private val jsonDecoder = Json{ ignoreUnknownKeys = true }

    val onMessages = callbackFlow<ChatSession> {
        val unsubscribe = db.collection("chat").doc(getSessionId()).onSnapshot(
            {
                val chatSession: ChatSession = jsonDecoder.decodeFromString(JSON.stringify(it.data()))
                console.log("received chatSession:" + Json.encodeToString(chatSession))
                messages.putAll(chatSession.messages)
                trySend(ChatSession(getSessionId(), messages))
            },
            { close(errorToException(it)) }
        )
        awaitClose { unsubscribe() }
    }

}
