package component

import Lang
import csstype.ClassName
import csstype.url
import db.firebase
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.key
import view.HiringPosition

external interface LogoIconProps : Props {
    var image: String
    var link: String
    var title: String
    var name: String
}

val LogoIcon = FC<LogoIconProps> { props ->
    div {
        a {
            className = ClassName("logo-icon")
            href = props.link
            title = props.title
            target = AnchorTarget._blank

            img {
                src = props.image
            }
        }
        span { +props.title }
    }
}
