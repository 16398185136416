package view

import Lang
import component.LogoIcon
import csstype.ClassName
import db.firebase
import emotion.react.css
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.PropsWithChildren
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.key

val TopHexBackground = FC<PropsWithChildren> { props ->
    div{
        className = ClassName("top-hex")
        + props.children
    }
}