package component

import Lang
import csstype.ClassName
import csstype.url
import db.firebase
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.key
import view.HiringPosition

external interface SectionHeadingProps : Props {
    var title: String
}

val SectionHeading = FC<SectionHeadingProps> { props ->
    div {
        className = ClassName("section-heading")

        h2 {
            +props.title
        }
        span {

        }
    }

}