package view

import Lang
import component.LogoIcon
import component.SectionHeading
import csstype.ClassName
import db.firebase
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span

@Serializable
data class Partner(
        val id: Int,
        val name: String,
        val description: String,
        val image: String,
)

external interface PartnerSectionProps : Props {
    var partners: List<Partner>
}

val PartnerSection = FC<PartnerSectionProps> { props ->
    div {
        id = "partner"
        className = ClassName("section")
        SectionHeading {
            title = Lang.getSelected().partners_title
        }
//        ReactHTML.p {
//           +Lang.getSelected().partners_description
//        }
        div {
            className = ClassName("icon-grid")
            props.partners.forEach {
                PartnerView {
                    partner = it
                }
            }
        }
    }
}

external interface PartnerProps : Props {
    var partner: Partner
}

val PartnerView = FC<PartnerProps> { props ->
    LogoIcon {
        image = props.partner.image
        title = props.partner.name
        name = props.partner.name
    }
}

