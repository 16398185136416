import kotlinext.js.require
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import view.*

@Serializable
data class Translation(
        val main_description: String,
        val header_book: String,
        val action_texts: List<ActionText>,
        val contact_form_email_input_placeholder: String,
        val contact_form_message_input_placeholder: String,
        val contact_form_name_input_placeholder: String,
        val contact_form_phone_input_placeholder: String,
        val contact_form_submit_input_placeholder: String,
        val contact_form_title: String,
        val contact_list_header: String,
        val contact_list_item_1: String,
        val contact_list_item_2: String,
        val contact_list_item_3: String,
        val contact_list_item_4: String,
        val contact_list_item_5: String,
        val contact_message: String,
        val contact_title: String,
        val footer_city_name: String,
        val footer_company_address: String,
        val footer_contact_phone: String,
        val footer_id_number: String,
        val footer_email_address: String,
        val footer_postal_code: String,
        val footer_vat: String,
        val header_menu_contact: String,
        val header_menu_home: String,
        val header_menu_language: String,
        val header_menu_products: String,
        val header_menu_services: String,
        val hiring_button: String,
        val hiring_positions: List<HiringPosition>,
        val hiring_title: String,
        val join_button: String,
        val join_list_item_1: String,
        val join_list_item_2: String,
        val join_list_item_3: String,
        val join_title: String,
        val join_message: String,
        val location_city_name: String,
        val location_company_address: String,
        val location_contact_phone: String,
        val location_country: String,
        val location_title1: String,
        val location_title2: String,
        val location_title3: String,
        val main_button_contact_us: String,
        val main_title1: String,
        val main_title2: String,
        val product_button: String,
        val product_title: String,
        val products: List<Product>,
        val service_title: String,
        val service_button: String,
        val services: List<Service>,
        val tech_stack: String,
        val tech_stack_description: String,
        val chat_title: String,
        val chat_info: String,
        val chat_status: String,
        val chat_input_placeholder: String,
        val chat_message_ask_info_email: String,
        val chat_message_ask_info_phone: String,
        val chat_message_fill_info_input_email: String,
        val chat_message_fill_info_input_phone: String,
        val faq_title: String,
        val faqs: List<FAQ>,
        val partners: List<Partner>,
        val partners_title: String,
        val partners_description: String
)

private val jsonDecoder: Json = Json { ignoreUnknownKeys = true }

fun loadFromFile(fileName: String): Translation {
    val languageFile = require("./language/${fileName}")
    val languageFileString = JSON.stringify(languageFile)
    return jsonDecoder.decodeFromString(languageFileString)
}

enum class Languages(
        val key: String,
        val shortcut: String,
        val label: String,
        val translation: Translation,
        val image: String
) {
    CZ("cs-CZ", "CZ", "Česky", loadFromFile("cs_CZ.json"), ""),
    EN("en-US", "EN", "English", loadFromFile("en_US.json"), ""),
//    DE("de-DE","DE", "German", loadFromFile("de_DE.json"), ""),
//    PL("pl-PL","PL", "Polish", loadFromFile("pl_PL.json"), ""),
//    FR("fr-FR", "FR","French", loadFromFile("fr_FR.json"),""),
}

object Lang {

    private var selectedLang = Languages.EN

    fun select(idOrLabel: String) {
        selectedLang = when (idOrLabel) {
            "en", "EN", "en-US" -> Languages.EN
            "cz", "CZ", "cs-CZ" -> Languages.CZ
//            "de", "DE", "de_DE" -> Languages.DE
//            "pl", "PL", "pl_PL" -> Languages.PL
//            "fr", "FR", "fr_FR" -> Languages.FR
            else -> Languages.EN
        }
    }

    fun getAll(): List<Languages> {
        return Languages.values().toList()
    }

    fun getSelected(): Translation {
        return selectedLang.translation
    }
}


