package view

import Lang
import component.JumpButton
import component.SectionHeading
import csstype.ClassName
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span

@Serializable
data class Service(
        val id: Int,
        val name: String,
        val description: String,
        val image: String,
        val extraText: List<String>
)

val ServiceSection = FC<Props> {
    div {
        className = ClassName("section listing")
        id = "services"
        SectionHeading{
            title = Lang.getSelected().service_title
        }
        div {
            className = ClassName("service-list")
            Lang.getSelected().services.forEach {
                ServiceView {
                    service = it
                }
            }
        }
    }
}


external interface ServiceProps : Props {
    var service: Service
}

val ServiceView = FC<ServiceProps> { props ->

    div {
        className = ClassName("service")
        div{
            className = ClassName("service-image")
            img{
                src = props.service.image
            }
        }
        h3 {
            +props.service.name
        }
        span {
            className = ClassName("description")
            +props.service.description
        }
        JumpButton{
            scrollTo = "#contact"
            + Lang.getSelected().service_button
        }
    }
}
