import kotlinx.serialization.Serializable

@Serializable
data class Contact(val name: String, val email: String)

@Serializable
data class SendInBlueEmailForm(
    val name: String,
    val email: String,
    val subject: String,
    val phone: String,
    val message: String
) {
    val sender = Contact(name, email)
    val to = listOf(Contact("CexbitWebsite", "support@cexbit.com"))
    val htmlContent: String =
        "<html>" +
                "<body>" +
                "There is website contact form message: Name: $name, Email: $email, Phone: $phone, Message: $message" +
                "</body>" +
                "</html>"
}

const val email_api_url = "https://api.sendinblue.com/v3/smtp/email"
const val email_api_key = "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"

@Serializable
data class SendInBlueEmailChat(
        val subject: String,
        val message: String
) {
    val sender = Contact("Cexbit website", "website@cexbit.com")
    val to = listOf(Contact("CexbitWebsite", "mirek@cexbit.com"))
    val htmlContent: String =
            "<html>" +
                    "<body>" +
                    message +
                    "</body>" +
                    "</html>"
}
