package component.reactDropdown

import org.w3c.dom.events.Event
import react.ElementType
import react.Props

val BootstrapDropdownMenu: ElementType<BootstrapDropdownMenuProps> = BootstrapDropdown.asDynamic().Menu
val BootstrapDropdownItem: ElementType<BootstrapDropdownItemProps> = BootstrapDropdown.asDynamic().Item
val BootstrapDropdownToggle: ElementType<BootstrapDropdownToggleProps> = BootstrapDropdown.asDynamic().Toggle
val BootstrapDropdownDivider: ElementType<BootstrapDropdownMenuProps> = BootstrapDropdown.asDynamic().Divider

external interface BootstrapDropdownMenuProps : Props {
    var show: Boolean
    var style: Any
    var rootCloseEvent: String
}

external interface BootstrapDropdownDividerProps : Props

external interface BootstrapDropdownItemProps : Props {
    var className: String
    var value: String
    var key: String
    var style: dynamic
    var href: String
    var onClick: (Event) -> Unit
    var onSelect: (Event) -> Unit
}

external interface BootstrapDropdownToggleProps : Props {
    var id: String
    var variant: String
    var split: Boolean
    var size: String
}
