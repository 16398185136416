import db.FirebaseJs
import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*

val jsonClient = HttpClient {
    install(ContentNegotiation) {
        json()
    }
}

suspend fun postEmail(email: String, name: String, phone: String, message: String): Boolean {
    val result = jsonClient.post(email_api_url) {
        contentType(ContentType.Application.Json)
        accept(ContentType.Application.Json)
        headers {
            header("api-key", email_api_key)
        }
        setBody(
                SendInBlueEmailForm(
                        name,
                        email,
                        "CexbitWebsite - kontaktní formulář",
                        phone,
                        message
                )
        )
    }
    return result.status in listOf(HttpStatusCode.OK, HttpStatusCode.Accepted, HttpStatusCode.Created)
}

suspend fun postEmailFromChat(message: String, sessionId: String, userInfo: String): Boolean {
    val result = jsonClient.post(email_api_url) {
        contentType(ContentType.Application.Json)
        accept(ContentType.Application.Json)
        headers {
            header("api-key", email_api_key)
        }
        setBody(
                SendInBlueEmailChat(
                    "CexbitWebsite - zpráva z chatu",
                    "Uživatel napsal z chatu sessionId: $sessionId message: $message " +
                            " UserInfo: $userInfo "

                )
        )
    }
    return result.status in listOf(HttpStatusCode.OK, HttpStatusCode.Accepted, HttpStatusCode.Created)
}
