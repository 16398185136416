package view

import Lang
import component.JumpButton
import component.SectionHeading
import csstype.ClassName
import db.firebase
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.span
import react.key

@Serializable
data class HiringPosition(
        val id: Int, val name: String,
        val description: String,
        @SerialName("form_pattern")
        val formPattern: String
)

external interface HiringSectionProps : Props {
    var hiringPositions: List<HiringPosition>
}

val HiringSection = FC<HiringSectionProps> { props ->
    div {
        id = "hiring"
        className = ClassName("section listing")
        div {
            SectionHeading {
                title = Lang.getSelected().hiring_title
            }
        }
        div {
            className = ClassName("listing")
            props.hiringPositions.forEach { item ->
                HiringPositionView {
                    hiringPosition = item
                }
            }
        }
    }
}

external interface HiringPositionProps : Props {
    var hiringPosition: HiringPosition
}

val HiringPositionView = FC<HiringPositionProps> { props ->
    div {
        className = ClassName("position")
        h3 {
            span {
                +props.hiringPosition.name
            }
        }
        span {
            +props.hiringPosition.description
        }
        JumpButton {
            className = ClassName("outline")
            +Lang.getSelected().hiring_button
            key = props.hiringPosition.id.toString()
            scrollTo = "#contact"
            onClick = {
                // TODO translation
                firebase.sendMessageWithContactRequest("Mám zájem se dozvědět více o této pozici: ${props.hiringPosition.name}. " +
                        "Prosím kontaktujte mě na ...")
            }
        }
    }
}
