package view

import Lang
import csstype.ClassName
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span

val Footer = FC<Props> {
    div {
        id = "footer"

        div {
            className = ClassName("horizontal")
            div {
                className = ClassName("logo")
                a {
                    href = "#"
                    img {
                        alt = "Cexbit.com"
                        src = "assets/cexbit_logo_min.svg"
                    }
                }
            }
            div {
                div { +"info@cexbit.com" }
                div { +"www.cexbit.com" }
            }

            div {
                div { +"CZ office: CEXBIT s.r.o." }
                div { +"Kollárova 1714/1" }
                div { +"500 02 Hradec Králové" }
                div { +"Czech Republic" }
                div { +"ICO 26967405 | DIC CZ26967405" }
            }
            div {
                div { +"US office: CEXBIT Inc." }
                div { +"90 State Street" }
                div { +"12207 Albany New York" }
                div { +"United States" }
                div { +"CRN 5355130" }
            }
//            div {
//                div { +"SAE office: CEXBIT " }
//                div { +"Coming soon ..." }
//            }
            div {
                className = ClassName("socials")
                a {
                    className = ClassName("ig")
                    img {
                        src = "assets/social/instagram_icon.svg"
                    }
                }

                a {
                    className = ClassName("fb")
                    img {
                        src = "assets/social/facebook_icon.svg"
                    }
                }

                a {
                    className = ClassName("li")
                    img {
                        src = "assets/social/linkedin_icon.svg"
                    }
                }
            }
        }
    }
}
